import { Component, ViewChild } from '@angular/core';
import { NavController, ToastController, IonSlides } from '@ionic/angular';
import { BrillianoApiService } from '../services/brilliano-api.service';


@Component({
  selector: 'demo',
  template: `
  <ion-content>
		<ion-slides #slides pager="true" [options]="slideOpts">
			<ion-slide class="slide1" padding>
				<img src="/assets/img/brilliano_logo.png" style="padding-bottom: 34px;">
				<p>Transform yourself and the world.</p>
				<ion-button (click)="next()">Next</ion-button>
			</ion-slide>
			<ion-slide class="slide2" padding>
				<h1>Discover meditations and practices</h1>
				<p>Transform yourself through meditations, practices, people, and live events.</p>
				<img src="https://api.brilliano.com/img/demo/slide1.png">
				<ion-button (click)="next()">Next</ion-button>
			</ion-slide>
			<ion-slide class="slide3" padding>
				<h1>Connect with others</h1>
				<p>Attend events related to your practice and join the discussion.</p>
				<img src="https://api.brilliano.com/img/demo/slide3.png">			
				<ion-button (click)="next()">Next</ion-button>
			</ion-slide>
			<ion-slide class="slide4" padding>
				<h1>Track Progres</h1>
				<p>Track your daily practices and gain research-based insights.</p>
				<img src="https://api.brilliano.com/img/demo/slide2.png">
				<ion-button (click)="next()" color="danger">Onward to Brilliano!</ion-button>
			</ion-slide>
		</ion-slides>
  </ion-content>
`,
	styleUrls: ['demo.scss'],
})
export class DemoPage {

	@ViewChild('slides', {static: true}) slides: IonSlides;
	
	next() {
		Promise.resolve()
		this.slides.isEnd().then((isEnd) => {
			if(isEnd) this.navCtrl.navigateRoot('/practice/hub');
			else {
				this.slides.slideNext();
			}
		});
	}

	slideOpts = {
		speed: 400,
	 };

  constructor(
	 public navCtrl: NavController,
	 public bapi: BrillianoApiService,
  ) {

  }


  ionViewWillEnter() {

  }
}
