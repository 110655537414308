import { Component } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular'

import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { UserService } from '../services/user.service';


@Component({
  selector: 'page-prefs',
  templateUrl: 'prefs.html'
})
export class PrefsPage {

  loaded : boolean = false;
  imageLoading : boolean = false;

  private userImage : string; // user's profile image
  private errors : any[] = [];
  private passwordErrors : any[] = [];
  private prefsForm : FormGroup;

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    private userService: UserService,
	 private formBuilder: FormBuilder,
	 private toastCtrl: ToastController
  ) {
    this.prefsForm = this.formBuilder.group({   
      name: ['',
        Validators.compose([
          Validators.required,
          Validators.maxLength(30)
        ])
      ],
      username: ['',
        Validators.compose([
          Validators.required,
          Validators.maxLength(16),
          Validators.pattern("[A-Za-z0-9_]+$")
        ])
      ],
      email: ['',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      password: ['', null],
		password2: ['', null],
		postEmail: ['', null],
		replyEmail: ['', null],
		eventEmail: ['', null],
    });
  }


  ionViewWillEnter() {
    this.userService.getPreferences().then((prefs : any) => {
		this.userImage = prefs.image;
		console.log("Setting initial preferences to:");
		console.log(prefs);
      this.prefsForm.setValue({
        name: prefs.name,
        username: prefs.username,
		  email: prefs.email,
		  postEmail: prefs.emailPost,
		  replyEmail: prefs.emailReply,
		  eventEmail: prefs.emailEvent,		  
        password: '',
		  password2: ''
      });
      this.loaded = true;
    });
  }


  validatePasswords() {

	if(!this.loaded) return;

	this.passwordErrors = [];

	let password = this.prefsForm.get('password');
	let password2 = this.prefsForm.get('password2');

	if(password.touched && (password.value != password2.value)) {
	  this.passwordErrors.push('Passwords do not match!');
	}  	  
  }


  validatePrefsForm() {
	
	if(!this.loaded) return;

    this.errors = [];

    let name = this.prefsForm.get('name');
    let username = this.prefsForm.get('username');
    let email = this.prefsForm.get('email');
    let password = this.prefsForm.get('password');
	 let password2 = this.prefsForm.get('password2');

    if(name.touched) {
      if(name.hasError('maxlength')) {
        this.errors.push('Name can only be 30 characters long!');
      }    
      if(name.hasError('required')) {
        this.errors.push('Name is required!');
      }
    }

    if(username.touched) {
      if(username.hasError('required')) {
        this.errors.push('Username is required!');
      }
      if(username.hasError('maxlength')) {
        this.errors.push('Username can only be 16 characters long!');
      }
      if(username.hasError('pattern')) {
        this.errors.push('Username can only be numbers and letters with no spaces!');
      }
    }

    if(email.touched) {
      if(email.hasError('required')) {
        this.errors.push('Email is required!');
      }
      if(email.hasError('email')) {
        this.errors.push('Not a valid email address!');
      }
    }

    if(password.touched && (password.value != password2.value)) {
      this.errors.push('Passwords do not match!');
    }  
  }


	async updateEmailPrefs() {
		const toast = await this.toastCtrl.create({
			message: 'Email preference saved',
			duration: 2000,
		});

		this.userService.savePreferences(
			null, // name
			null, //username
			null, //email
			this.prefsForm.get('postEmail').value,
			this.prefsForm.get('replyEmail').value,
			this.prefsForm.get('eventEmail').value,
			null //password
		).then((res: any) => {
			console.log("res: ");
			console.log(res);
		});

		toast.present();
	}


  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      let file: File = fileList[0];
      this.imageLoading = true;
      this.userService.saveProfileImage(file).then((res) => {
        this.userService.getPreferences().then((prefs: any) => {
          this.userImage = prefs.image;
        });
        event.target.value = "";
        this.imageLoading = false;
      }).catch((err) => {
        this.imageLoading = false;
      });
    }
}  


 async savePrefs() {
	 let user : any = this.prefsForm.value;
	 let password : string = null;

    this.validatePrefsForm();
    if(this.errors.length > 0 || this.passwordErrors.length > 0) {
      console.log("Error detected!");
      return;
	 }
	 
	 if(user.password && user.password2 && user.password === user.password2) {
		password = user.password;
	 }

	 const alert = await this.alertCtrl.create({
		header: "Saved",
		message: "Your preferences were successfully updated!",
		buttons: [
		  {
			 text: 'OK',
		  //   handler: data => {
		  //     this.navCtrl.back
		  //   }
		  }
		]
	 });	 

    this.errors = [];
    this.userService.savePreferences(
      user.name,
      user.username,
      user.email,
		user.postEmail,
		user.replyEmail,
		user.eventEmail,
		user.password
    ).then((res: any) => {
      this.prefsForm.controls.password.patchValue('');
		this.prefsForm.controls.password2.patchValue('');

		alert.present();
	}).catch((err: any) => {
		let errors = err.error.errors
      if(errors) { // validation errors
        console.log("Validation errors:");
        for(let key in errors) {
          // Flatten/append array of errors
          this.errors = this.errors.concat(errors[key]); // Incantation : https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript
          return;
        }
      } else {
		  alert.present();
      }
	});

    
  }
}
