import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { PrefsPage } from './prefs/prefs';
import { DemoPage } from './demo/demo';

const routes: Routes = [
	{ path: '', redirectTo: '/practice/journey/evigram', pathMatch: 'full' },
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'signup',
		loadChildren: () => import('./login/signup.module').then(m => m.SignupModule)
	},	
	{
		path: '',
		canActivate: [AuthGuard],
		children: [
			{ path: 'community', loadChildren: () => import('./community/community.module').then(m => m.CommunityModule) },
			{ path: 'activity', loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule) },
			{ path: 'practice', loadChildren: () => import('./practice/practice.module').then(m => m.PracticeModule) },
			{ path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsPageModule) },
			{ path: 'preferences', component: PrefsPage },
			{ path: 'demo', component: DemoPage },
		],
	},
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
