import { Component, ViewChild } from '@angular/core';

import {Platform, IonNav, ModalController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
	public menuPages = [		
		{
		  title: 'Profile and preferences',
		  url: '/preferences',
		  icon: 'person'
		},
	 ];	

  constructor(
    private platform: Platform,
    public modalCtrl: ModalController,
    public user: UserService,
    public router: Router,
  ) {
  }

  logout() {
    console.log("Logging out user");
    this.user.logout().then(() => this.router.navigate(['/login']));
  }

  feedback() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeB1y8OLnN8dMowVBGOTOGgNcfLPbhNad0yXR7ciuOcY-2X4Q/viewform?usp=sf_link', '_blank');
  }
}
