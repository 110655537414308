import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { EventRepeat } from '../community/events/eventRepeat';


@Injectable({providedIn: 'root'})
export class BrillianoApiService {

  static BASE_URL: string = 'https://api.brilliano.com/api/';

  constructor(public http: HttpClient) {

  } 

  getChannels() {
	  return this.http.get(BrillianoApiService.BASE_URL + 'channel/getChannels')
	  		.pipe(timeout(5000))
			.toPromise();
  }

  channelPosts(channelid: number, page: number, pageSize?: number) {
    let params = new HttpParams().set('page', page.toString());
    if(pageSize) params = params.append('pageSize', pageSize.toString());
	 if(channelid) params = params.append('channelId', channelid.toString());

    return this.http.get(BrillianoApiService.BASE_URL + 'channel/posts', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  favoriteChannel(groupId: number, favorite: boolean) {
	let params = new HttpParams();
	params = params.append('groupId', groupId.toString());
	params = params.append('favorite', favorite ? 'true' : 'false');

	return this.http.post(BrillianoApiService.BASE_URL + 'channel/favorite', null, {params: params})
	  .pipe(timeout(5000))
	  .toPromise();      
  }  

	muteChannel(groupId: number, mute: boolean) {
		let params = new HttpParams();
		params = params.append('groupId', groupId.toString());
		params = params.append('mute', mute ? 'true' : 'false');

		return this.http.post(BrillianoApiService.BASE_URL + 'channel/mute', null, { params: params })
			.pipe(timeout(5000))
			.toPromise();
	}

  createChannelPost(channelId: number, text: string) {
    let params = new HttpParams();
    params = params.append('channelId', channelId.toString());
    params = params.append('text', text);
    
    return this.http.post(BrillianoApiService.BASE_URL + 'channel/post', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();     
  }

  fullPost(postId: number): Promise<any> {
    let params = new HttpParams();
    params = params.append('postId', postId.toString());
    
    return this.http.get(BrillianoApiService.BASE_URL + 'post/post', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }


  likePost(postId: number, like: boolean) {

    let action = like ? 'unlike' : 'like';

    let params = new HttpParams();
    params = params.append('postId', postId.toString());
    params = params.append('action', action.toString());
    
    return this.http.post(BrillianoApiService.BASE_URL + 'post/like', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }


  postComment(postId: number, text: string) {
    let params = new HttpParams();
    params = params.append('postId', postId.toString());
    params = params.append('text', text);
    
    return this.http.post(BrillianoApiService.BASE_URL + 'post/comment', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

	/*
	*
	* Journey
	*
	*/

	getJourneyPageInfo() {
		return this.http.get(BrillianoApiService.BASE_URL + 'journey/getJourneyPageInfo')
			.pipe(timeout(5000))
			.toPromise();
	}

  getJourney(userid: number) {
    let params = new HttpParams();
    params = params.append('userid', userid.toString());

    return this.http.get(BrillianoApiService.BASE_URL + 'journey/status', {params: params})
      .pipe(timeout(5000))
      .toPromise();     
  }  

  getSurveyToken(surveyid: number, userid: number) {
    let params = new HttpParams();
    params = params.append('surveyid', surveyid.toString());
    params = params.append('userid', userid.toString());

    return this.http.get(BrillianoApiService.BASE_URL + 'journey/token', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

	getPracticesList(favorites?: boolean) {
		let params = new HttpParams();
		params = favorites ? params.append('favorites', favorites.toString()) : params;

		return this.http.get(BrillianoApiService.BASE_URL + 'practice/list', {params: params})
			.pipe(timeout(5000))
			.toPromise();
	}

	favoriteGuide(guideid: number) {
		let params = new HttpParams();
		params = params.append('guideid', guideid.toString());

		return this.http.post(BrillianoApiService.BASE_URL + 'practice/guideFavorite', null, { params: params })
			.pipe(timeout(5000))
			.toPromise();
	}

  getPractice(groupId: number) {
    let params = new HttpParams();
    params = params.append('groupid', groupId.toString());

    return this.http.get(BrillianoApiService.BASE_URL + 'practice/getGuide', {params: params})
      .pipe(timeout(5000))
      .toPromise();    
  }

  getInsightsList() {
    return this.http.get(BrillianoApiService.BASE_URL + 'insights/list')
      .pipe(timeout(5000))
      .toPromise();
  }  

  /*
   * 
   * Activity Tracker 
   * 
   */

  async logTrackerEvent(event: any, activities: any[]) {
    let params = new HttpParams();

    if(event.eventid) { // Update
      params = params.append('eventid', event.eventid.toString());  
    }

    params = params.append('date', event.date);
    params = event.duration ? params.append('duration', event.duration) : params;
	 params = params.append('valence', event.valence.toString());
	 if(event.notes) {
		params = params.append('notes', event.notes);
	 }
    

    return this.http.post(
      BrillianoApiService.BASE_URL + 'tracker/logEvent',
      activities,
      {params: params}
    ).pipe(timeout(5000))
     .toPromise();
  }

  createActivity(name: string, type: string, activityType: string) {
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('type', type);
    params = params.append('activitytype', activityType);

    return this.http.post(BrillianoApiService.BASE_URL + 'tracker/createActivity', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  deleteTrackerActivity(id: string) {
    let params = new HttpParams();
    params = params.append('id', id);

    return this.http.post(BrillianoApiService.BASE_URL + 'tracker/deleteActivity', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();    
  }

  getTrackerEventHistory() {
    return this.http.get(BrillianoApiService.BASE_URL + 'tracker/getHistory')
      .pipe(timeout(5000))
      .toPromise();
  }

  getTrackerEventDetailsById(eventid: number) {
    let params = new HttpParams();
    params = params.append('id', eventid.toString());

    return this.http.get(BrillianoApiService.BASE_URL + 'tracker/eventDetailsById', {params: params})
      .pipe(timeout(5000))
      .toPromise();    
  }

  getTrackerEventDetailsByDate(date: string) {
    let params = new HttpParams();
    params = params.append('date', date);

    return this.http.get(BrillianoApiService.BASE_URL + 'tracker/eventDetailsByDate', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  deleteTrackerEvent(id: number) {
    let params = new HttpParams();
    params = params.append('eventid', id.toString());

    return this.http.post(BrillianoApiService.BASE_URL + 'tracker/deleteEvent', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();    
  }  

  getPreviousEventByDate(date: string) {
    let params = new HttpParams();
	 params = params.append('date', date);

    return this.http.get(BrillianoApiService.BASE_URL + 'tracker/getPreviousEventByDate', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  getMoodOverTime() {
    return this.http.get(BrillianoApiService.BASE_URL + 'tracker/getMoodOverTime')
      .pipe(timeout(5000))
      .toPromise();
  }

  getMoodAndEvents(mood: string, category: string, timerange: string) {
	let params = new HttpParams();
	params = params.append('mood', mood);
	params = params.append('category', category);
	params = params.append('timerange', timerange);

	return this.http.get(BrillianoApiService.BASE_URL + 'tracker/getMoodAndEvents', {params: params})
	  .pipe(timeout(5000))
	  .toPromise();
 }

 trackPractice(date: string, practiceId: number) {
	let params = new HttpParams();
	params = params.append('date', date);
	params = params.append('practiceid', practiceId.toString());

	return this.http.post(BrillianoApiService.BASE_URL + 'tracker/trackPractice', null, {params: params})
	  .pipe(timeout(5000))
	  .toPromise();
 }


  /**
   * 
   * Community Events
   * 
   */
	createCommunityEvent(
		channelid: number,
		name: string,
		description: string,
		starttime: string,
		endtime: string,
		location: string,
		timezone: string,
    repeat: EventRepeat
	) {
		let params = new HttpParams();
		params = params.append('channelid', channelid.toString());
		params = params.append('name', name);
		params = params.append('description', description ? description : '');
		params = params.append('starttime', starttime);
		params = params.append('endtime', endtime);
		params = params.append('location', location);
		params = params.append('timezone', timezone.split(' ').join('_'));
    params = params.append('repeats', (repeat.repeats ? 'true' : 'false'));
    params = params.append('repeatFrequency', repeat.repeatFrequency);
    params = params.append('repeatDaysOfWeek', (repeat.daysOfWeekWeekly ? JSON.stringify(repeat.daysOfWeekWeekly) : null));
    params = params.append('repeatEvery', repeat.repeatsEvery.toString());
    params = params.append('repeatEndsOn', repeat.endsOn.toString());

		return this.http.post(BrillianoApiService.BASE_URL + 'event/new', null, { params: params })
			.pipe(timeout(15000))
			.toPromise();
	}

  countrytimezones() {
    return this.http.get(BrillianoApiService.BASE_URL + 'event/timezones')
      .pipe(timeout(5000))
      .toPromise();    
  }


  communityEventList(channelid: number) {
	let params = new HttpParams();
	params = params.append('channelid', channelid.toString());

    return this.http.get(BrillianoApiService.BASE_URL + 'event/list', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  /**
   * Parameters
   * edit: True if you want to return the original text vs. HTML formatted
   */
  communityEventDetails(eventId: number, timezone: string, edit: boolean = false, seriesId: number = null) {
    let params = new HttpParams();
    params = params.append('eventId', eventId.toString());
    params = params.append('timezone', timezone);
    params = params.append('edit', edit ? "true" : "false");
    if(seriesId) {
      params = params.append('seriesId', seriesId.toString());
    }
        
    return this.http.get(BrillianoApiService.BASE_URL + 'event/details', {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  eventResponse(eventId: number, response: string, seriesId: number = 0) {
    let params = new HttpParams();
    params = params.append('eventId', eventId.toString());
    params = params.append('response', response);
    params = params.append('seriesID', seriesId.toString());

    return this.http.post(BrillianoApiService.BASE_URL + 'event/response', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();    
  }

  saveUserTimezone(timezone: string) {
    let params = new HttpParams();
    params = params.append('timezone', timezone);

    //TODO: May want the path to be user/timezone (user preference) instead.
    return this.http.post(BrillianoApiService.BASE_URL + 'event/timezone', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  userTimezone() {
    return this.http.get(BrillianoApiService.BASE_URL + 'event/mytimezone')
      .pipe(timeout(5000))
      .toPromise();
  }

  eventEdit(
    eventId: number,
    name: string, 
    description: string,
    starttime: string,
    endtime: string,
    location: string,
    timezone: string
  ) {
    let params = new HttpParams();
    params = params.append('eventId', eventId.toString());
    params = params.append('name', name);
    params = params.append('description', description);
    params = params.append('starttime', starttime);
    params = params.append('endtime', endtime);
    params = params.append('location', location);
    params = params.append('timezone', timezone.split(' ').join('_'));

    return this.http.post(BrillianoApiService.BASE_URL + 'event/edit', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();
  }

  eventDelete(eventId: number) {
    let params = new HttpParams();
    params = params.append('eventId', eventId.toString());

    return this.http.post(BrillianoApiService.BASE_URL + 'event/delete', null, {params: params})
      .pipe(timeout(5000))
      .toPromise();    
  }

  getNotifications() {
    return this.http.get(BrillianoApiService.BASE_URL + 'account/notifications')
      .pipe(timeout(5000))
      .toPromise();
  }

	notificationsNewCount() {
    return this.http.get(BrillianoApiService.BASE_URL + 'account/notificationsnewcount')
      .pipe(timeout(5000))
      .toPromise();    
  }

}