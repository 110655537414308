import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { UserService } from '../../services/user.service';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';

@Component({
  selector: 'page-splash',
  templateUrl: 'splash.html',
})
export class SplashPage {

  constructor(
    public navParams: NavParams,
    // public splashScreen: SplashScreen,
    public userService: UserService
  ) {
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad SplashPage');
  }

  ionViewDidEnter() {
    // this.splashScreen.hide();
  }  

}
