import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BrillianoApiService } from './brilliano-api.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { from } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserService extends BrillianoApiService {

  // User data
  username: string;
  userid: number;
  name: string;
  profileThumb: string;
  is_guide: boolean;

  // Authentication data

  token: string;
  expiration: number;
  _storage: Storage;


  constructor(
    public http: HttpClient,
    public storage: Storage
   ) {

    super(http);
    this.createUserService();

  }

  async createUserService() {
    console.log("User Service Constructor");
    this._storage = await this.storage.create();
    let user = await this._storage.get('user');
    
      if(user == null) {
        //TODO
      } else {
        this.username = user['username'];
        this.token = user['token'];
        this.expiration = parseInt(user['expiration']);
        this.userid = parseInt(user['userid']);
        this.name = user['name'];
        this.profileThumb = user['profileThumb'];
        this.is_guide = user['is_guide'];
      }
  }

  // Log in to Brilliano with supplied username and password
  // Uses JWT for authentication and a RESTful (JSON) API.
  // Modeled after: https://auth0.com/blog/ionic-2-authentication-how-to-secure-your-mobile-app-with-jwt/
  login(username: string, password: string) {
	 // Attempt login
	 const httpOptions = {
		headers: new HttpHeaders( {
			"Authorization": "Basic " + btoa(username + ":" + password),
			"Content-Type": "application/x-www-form-urlencoded",
		})
	 };   

    return new Promise((resolve, reject) => {
      this.http.post(
        BrillianoApiService.BASE_URL + "account/token", null, httpOptions
      ) .pipe(timeout(5000))
        .subscribe(
          (res: any) => {
            let saveToken = from(this.storage.set('accessToken', res.accessToken));
            let saveUser = from(
              this.storage.set('user', res.user).then(() => {
                // Create user object
                this.username = res.user.username;
                this.token = res.user.token;
                this.expiration = parseInt(res.user.expiration);
                this.userid = parseInt(res.user.userid);
                this.name = res.user.name;
                this.profileThumb = res.user.profileThumb;
                this.is_guide = res.user.is_guide;
              })
            );
            resolve(Promise.all([saveToken, saveUser]));
          },
          err => {
				 console.log("Error subscribing to account token request:");
				 console.log(err);
          this.storage.clear().then(() => {
            this.expiration = null;
            this.token = null;
          });
          reject();
        })
    });
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // If not, see if user information is in local storage
      const token = this._storage.get('accessToken').then((token) => {
        if (token) {
          resolve(token);
        } else {
          console.log("Token not found!");
          reject('Token not found!');
        }
      }).catch((err) => {
        console.log("Error. Could not get access token.");
        console.log(err);
        reject(err);
      });
    });
  }

  async logout() : Promise<void> {
	 console.log("Logout()");
    this.token = null;
    this.expiration = null;
    this.username = null;
    this.userid = null;
    this.name = null;
    this.profileThumb = null;
    this.is_guide = null;

    // return this.storage.clear();

    const storage = await this.storage.create();
    return storage.clear();
    // return this.storage.ready().then(() => {
    //   this.storage.clear();
    // });
  }

  signup(name, username, email, password) {
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('username', username);
    params = params.append('email', email);
    params = params.append('password', password);

    return new Promise((resolve, reject) => {
      this.http.post(
        BrillianoApiService.BASE_URL + "account/signup", null, { params: params }
      )
        .pipe(timeout(5000))
        .subscribe(
          (res) => {resolve(res)},
          (err) => {reject(err)}
        );
    });
  }

  getPreferences() {
    return new Promise((resolve, reject) => {
      this.http.get(BrillianoApiService.BASE_URL + "account/getPreferences")
      .pipe(timeout(5000))
      .subscribe(
        (res) => {resolve(res)},
        (err) => {reject(err)}
      );
    });
  }

  saveProfileImage(file: File) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('image', file, file.name);

      this.http.post(
        BrillianoApiService.BASE_URL + 'account/saveProfileImage',
        formData,
      )          
        .pipe(timeout(5000))
        .subscribe(
          (res) => {resolve(res)},
          (err) => {reject(err)}
        );
    });
  }

  async savePreferences(
		name: string,
		username: string,
		email: string,
		emailPostNotification: boolean,
		emailReplyNotification: boolean,
		emailEventNotification: boolean,
		password?: string
	) {  
      let params = new HttpParams();
      params = name ? params.append('name', name) : params;
      params = username ? params.append('username', username) : params;
		params = email ? params.append('email', email) : params;
		params = params.append('emailPost', emailPostNotification ? '1' : '0');
		params = params.append('emailReply', emailReplyNotification ? '1' : '0');
		params = params.append('emailEvent', emailEventNotification ? '1' : '0');		
		params = password ? params = params.append('password', password) : params;

      return this.http.post(
        BrillianoApiService.BASE_URL + 'account/savePreferences',
        null,
        {params: params}
      ).pipe(timeout(5000))
        .toPromise();
  }
}
