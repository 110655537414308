import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AudioProvider {
	private stop$ = new Subject();
	private audioObj = new Audio();

	constructor() { }

	private streamObservable(url: string) {
		let events = [
			'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
		];

		const addEvents = function (obj, events, handler) {
			events.forEach(event => {
				obj.addEventListener(event, handler);
			});
		};

		const removeEvents = function (obj, events, handler) {
			events.forEach(event => {
				obj.removeEventListener(event, handler);
			});
		};

		return Observable.create(observer => {
			// Play audio
			this.audioObj.src = url;
			this.audioObj.load();
			this.audioObj.play();

			// Media Events
			const handler = (event) => observer.next(event);
			addEvents(this.audioObj, events, handler);

			return () => { // Audio streaming Observerable cleanup
				// Stop Playing
				this.audioObj.pause();
				this.audioObj.currentTime = 0;

				// Remove EventListeners
				removeEvents(this.audioObj, events, handler);
			};
		});
	}

	playStream(url) : Observable<any> {
		return this.streamObservable(url).pipe(takeUntil(this.stop$)); // stream until stop$ emits
	}

	play() {
		this.audioObj.play();
	}

	pause() {
		this.audioObj.pause();
	}

	stop() {
		this.stop$.next(); // emit
	}

	seekTo(seconds) {
		this.audioObj.currentTime = seconds;
	}

	formatTime(time, format) {
		return moment.utc(time).format(format);
	}

}