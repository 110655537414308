import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { resolve } from 'q';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

	constructor(
		private userService: UserService,
		public router: Router
	) { }

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.canActivate(childRoute, state);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {	
		console.log('AuthGuard#canActivate called');
		return this.checkLoggedIn();
	}

	checkLoggedIn(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.userService.isAuthenticated()
				.then(() => {
					console.log("Auth check succeeded");
					resolve(true);
				})
				.catch(() => {
					console.log("Auth check unsuccessful");
					resolve(this.router.navigateByUrl('/login'));
				});
		});
	}
}
