import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, LoadingController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SplashPage } from './login/splash/splash';
import { PrefsPage } from './prefs/prefs';

// Providers

import { BrillianoApiService } from './services/brilliano-api.service';
import { UserService } from './services/user.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AudioProvider } from './services/audio';
import { Store, StateObservable, StoreModule } from '@ngrx/store';
import { mediaStateReducer } from './services/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoPage } from './demo/demo';

export function jwtOptionsFactory(storage: Storage) {
	return {
		throwNoTokenError: false,
		whitelistedDomains: ['api.brilliano.com', 'brilliano.com', 'www.brilliano.com'],
		// Add any authorization routes here!
		// blacklistedRoutes: ['www.brilliano.com/api/account/token', 'brilliano.com/api/account/token'],
		tokenGetter: () => {
			return storage.get('accessToken');
		}
	}
}

@NgModule({
	declarations: [AppComponent, SplashPage, PrefsPage, DemoPage],
	entryComponents: [SplashPage],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			mode: 'md'
		}),
		IonicStorageModule.forRoot(),
		StoreModule.forRoot({
			appState: mediaStateReducer
		 }),
		AppRoutingModule,
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: jwtOptionsFactory,
				deps: [Storage]
			}
		}),
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule
	],
	providers: [
		// StatusBar,
		// SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		BrillianoApiService,
		UserService,
		LoadingController,
		AudioProvider,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
